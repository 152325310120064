<template>
    <div>
        <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
            <el-form-item label="平台单号">
                <el-input v-model="formParam.externalOrderNo" placeholder="平台单号/微信子单号" clearable></el-input>
            </el-form-item>
            <el-form-item label="微信主单号">
                <el-input v-model="formParam.externalMainOrderNo" placeholder="微信主单号" clearable></el-input>
            </el-form-item>
            <el-form-item label="奶卡/寄售单号">
                <el-input v-model="formParam.sourceOrderMainNo" placeholder="奶卡/寄售单号" clearable></el-input>
            </el-form-item>
            <el-form-item label="收货人">
                <el-input v-model="formParam.consigneeName" placeholder="收货人" clearable></el-input>
            </el-form-item>
            <el-form-item label="收货人电话">
                <el-input v-model="formParam.consigneeMobile" placeholder="收货人电话" clearable></el-input>
            </el-form-item>
            <el-form-item label="平台">
                <el-select v-model="formParam.shopName" placeholder="请选择">
                    <el-option label="" value="全部"></el-option>
                    <el-option label="微信小程序" value="微信小程序"></el-option>
                    <el-option label="微信商城" value="微信商城"></el-option>
                    <el-option label="微信私域" value="微信私域"></el-option>
                    <el-option label="京东POP-低温" value="京东POP-低温"></el-option>
                    <el-option label="京东POP-常温" value="京东POP-常温"></el-option>
                    <el-option label="天猫商城" value="天猫商城"></el-option>
                    <el-option label="天猫oneshot旗舰店" value="天猫oneshot旗舰店"></el-option>
                    <!-- <el-option label="渠道线下店铺" value="渠道线下店铺"></el-option> -->
                     <el-option label="渠道一件代发" value="渠道一件代发"></el-option>
                    <el-option label="抖店" value="抖店"></el-option>
                    <el-option label="拼多多官方旗舰店" value="拼多多官方旗舰店"></el-option>
                    <el-option label="拼多多旗舰店" value="拼多多旗舰店"></el-option>
                    <el-option label="快手小店" value="快手小店"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                    <el-option label="每日元气-抖店" value="每日元气-抖店"></el-option>
                    <el-option label="视频号小店" value="视频号小店"></el-option>
                    <el-option label="有赞" value="有赞"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchOrder">查询</el-button>
                <el-button type="primary" @click="getThirdOrder">抓取订单</el-button>
<!--                <el-button type="primary" @click="getTMorder">抓取天猫订单</el-button>
                <el-button type="primary" @click="getJDorder">抓取京东订单</el-button>
                <el-button type="primary" @click="getMallorder">抓取微信/私域订单</el-button>-->
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :currentPage="formParam.currentPage" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                :data="tableList" border size="mini">
<!--                <el-table-column type="expand">-->
<!--                    <template slot-scope="props">-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">OMS订单号:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ props.row.orderNo }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">物流单号:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ props.row.logisticsNo }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">提货日期:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ formatDate(props.row.pickupTime) }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">配送日期:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ formatDate(props.row.deliveryTime) }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">买家留言:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ props.row.consigneeRemark }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">卖家留言:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ props.row.remark }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex"-->
<!--                                v-if="formParam.shopName=='微信商城' || formParam.shopName=='微信小程序'">-->
<!--                            <el-col :span="2" class="expand-title">购卡主单号:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->
<!--                                {{ props.row.sourceOrderMainNo }}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                        <el-row type="flex" class="expand-flex">-->
<!--                            <el-col :span="2" class="expand-title">商品明细:</el-col>-->
<!--                            <el-col :span="2" class="expand-content">-->

<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="externalOrderNo" label="订单号" width="260px">
                    <template slot-scope="scope">
                        <div style="font-size: 14px;font-weight: 600;margin-bottom: 10px">{{scope.row.externalOrderNo}} <i style="color: #55a532;cursor: pointer" @click="copyOrderNo(scope.row.externalOrderNo)" class="el-icon-document-copy"></i></div>
                        <div style="font-size: 10px;">
                            OMS: {{scope.row.orderNo}}
                        </div>
                        <div style="font-size: 10px;" v-if="scope.row.shopId=='1325743461067264001'||scope.row.shopId=='1325743461067264000'">
                            主单号: {{scope.row.externalMainOrderNo}}
                        </div>
                    </template>
                </el-table-column>


                <!--                <el-table-column prop="consigneeRemark" label="买家留言"></el-table-column>-->
                <!--                <el-table-column prop="remark" label="卖家留言"></el-table-column>-->
                <!--<el-table-column prop="orderNo" label="订单号"></el-table-column>-->
                <!--                <el-table-column prop="sourceOrderMainNo" label="订单来源主单号"></el-table-column>-->
                <!--                <el-table-column prop="shopName" label="渠道"></el-table-column>-->
                <el-table-column label="买家信息" width="190px">
                    <template slot-scope="scope">
                        <template v-if="scope.row.shopId=='1325743461067264001'||scope.row.shopId=='1325743461067264000'">
                            <div>
                                <el-link style="font-size: 10px"><span style="color: #2dacd1;font-size: 10px">● </span>{{scope.row.buyerNickname||'-'}}</el-link>
                            </div>
                            <el-link @click="toBuyer(scope.row.buyerId)" style="font-size: 10px" ><span style="color: #2dacd1;font-size: 10px">● </span>{{scope.row.buyerId||'-'}}</el-link>
                        </template>
                        <div v-else style="font-size: 10px"><span style="color: #2dacd1;font-size: 10px">● </span>{{scope.row.buyerNickname||'-'}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="consigneeName" label="收货人信息" min-width="200px">
                    <template slot-scope="scope">
                        <div style="position: relative">
                            <el-button v-if="scope.row.orderStatus == 10" @click="toEditDelivery(scope.row)" style="position: absolute;top: 0;right: 0;margin: 0;padding: 0;color:#e85656;" size="small" type="text" icon="el-icon-edit-outline"></el-button>
                            <div class="user-info"><i class="el-icon-user"></i>: {{scope.row.consigneeName||'-'}}</div>
                            <div class="user-info"><i class="el-icon-mobile"></i>: {{scope.row.consigneeMobile||'-'}}</div>
                            <div class="user-info address"><i class="el-icon-location-outline"></i>: {{scope.row.addressHtml||'-'}}</div>
                        </div>

                    </template>
                </el-table-column>
                <!--                <el-table-column prop="payTime" label="支付时间">-->
                <!--                   <template slot-scope="scope">-->
                <!--                       <span>{{formatDate(scope.row.payTime)}}</span>-->
                <!--                   </template>-->
                <!--                </el-table-column>-->
                <el-table-column label="订单日期" width="140px">
                    <template slot-scope="scope">
                        <div><span>下单: </span>{{ formatDate(scope.row.createTime) }}</div>
                        <div><span>提货: </span>{{ formatDate(scope.row.pickupTime) ||'-'}}</div>
                        <div><span>配送: </span>{{ formatDate(scope.row.deliveryTime)||'-' }}
                            <el-button v-if="scope.row.orderStatus == 10" @click="toEditDeliveryTime(scope.row)" style="margin: 0;padding: 0;color:#e85656;" size="small" type="text" icon="el-icon-edit-outline"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="orderSonTypeName" label="订单类型" width="120px"></el-table-column>
                <el-table-column prop="orderStatus" label="订单状态" width="90px">
                    <template slot-scope="scope">
                        <el-tag effect="dark" size="mini" :type="orderStatusState[scope.row.orderStatus].type">{{orderStatusState[scope.row.orderStatus].name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="orderFlowSignName" label="流转情况" min-width="170px">
                    <template slot-scope="scope">
                        <div style="position: relative;">
                            <span style="font-weight: 600;"><span :style="{color: scope.row.orderFlowSign==1?'#55a532':'#e50011'}">● </span>{{scope.row.orderFlowSignName}}</span>
                            <p v-if="scope.row.erroMessageNameHtml" class="copy-text" slot="reference" v-html="scope.row.erroMessageNameHtml"></p>
                        </div>
                    </template>
                </el-table-column>


                <!--                <el-table-column prop="deliveryTime" label="配送日期" width="100px">-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ formatDate(scope.row.deliveryTime) }}-->
<!--                        <el-button v-if="scope.row.deliveryTime&&scope.row.orderStatus == 10" @click="toEditDeliveryTime(scope.row)" style="margin: 0;padding: 0;color:#e85656;" size="small" type="text" icon="el-icon-edit-outline"></el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <!--                <el-table-column prop="pickupTime" label="提货时间">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <span>{{formatDate(scope.row.pickupTime)}}</span>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <!--                <el-table-column prop="orderCreateMethod" label="创单方式" :formatter="formatOrderCreateMethod"></el-table-column>-->
                <el-table-column label="订单异常" width="90px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.erroType==3"><i style="color: #67c23a" class="el-icon-success"></i>正常</span>
                        <span v-else-if="scope.row.erroType==1"><i style="color: #f56c6c" class="el-icon-error"></i>商品错误</span>
                        <span v-else-if="scope.row.erroType==2"><i style="color: #e6a23c" class="el-icon-warning"></i>其他错误</span>
                    </template>
                </el-table-column>
                <el-table-column prop="erroType" label="备注/留言" min-width="100px">
                    <template slot-scope="scope">
                        <div v-if="scope.row.consigneeRemark"><span style="color: rgb(230, 162, 60)">● </span>买家留言:{{ scope.row.consigneeRemark }}</div>
                        <div v-if="scope.row.remark"><span style="color: rgb(230, 162, 60)">● </span>备注:{{ scope.row.remark }}</div>
                    </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button @click="toDetail2(scope.row.externalOrderNo)" type="text" size="small">查看</el-button>
                        <el-button @click="orderFlow(scope.row.orderNo)" type="text" size="small">流转</el-button>
                        <el-dropdown style="margin-left: 16px" placement="bottom-start" @command="handleGroupItem">
                            <el-button type="text" class="el-dropdown-link">
                                •••
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
<!--                                <el-dropdown-item v-if="scope.row.orderSonType == 15 || ((scope.row.shopName == '微信小程序' || scope.row.shopName == '微信商城') && (scope.row.orderStatus != 0 && scope.row.orderStatus != 5 && scope.row.orderStatus != 45) && scope.rows.erroType == 3)" :command="{state:'refundOrder',item:scope.row}">退款</el-dropdown-item>-->
                                <el-dropdown-item v-if="scope.row.orderSonType == 15 || ((scope.row.shopName == '微信小程序' || scope.row.shopName == '微信商城') && (scope.row.orderStatus != 0 && scope.row.orderStatus != 5 && scope.row.orderStatus != 45))" :command="{state:'refundOrder',item:scope.row}">退款</el-dropdown-item>
                                <el-dropdown-item :command="{state:'remarkOrder',item:scope.row}">备注拦单</el-dropdown-item>
                                <el-dropdown-item v-if="(scope.row.orderCreateMethod == 2 && scope.row.orderStatus == 5) && scope.row.erroType == 3" :command="{state:'checkOrder',item:scope.row}">审批</el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.orderSource == 42" :command="{state:'cancelOrder',item:scope.row}">取消计划</el-dropdown-item>
                                <el-dropdown-item  :command="{state:'setOrderRecommend',item:scope.row}">设置补差价</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                </el-table>

            <el-dialog
                title="修改收货人信息(都是必填项)"
                :visible.sync="drawerShow"
                :before-close="handleClose">
                <el-form ref="form" :model="formData" label-width="80px" class="draw-box">
                    <el-form-item label="子订单号" required>
                        <el-input v-model="formData.externalOrderNo" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="收件人" required>
                        <el-input v-model="formData.consigneename"></el-input>
                    </el-form-item>
                    <el-form-item label="收件手机" required>
                        <el-input v-model="formData.consingeephone" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="地址选择" required>
                        <el-cascader
                            ref="cascaderAddr"
                            placeholder="请选择省市区"
                            v-model="cityValue"
                            :options="addressCityList"
                            :props="{value:'Id',label:'Name',children:'VoList'}"
                            @change="handleCasChange"></el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" required>
                        <el-input v-model="formData.address"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addressSubmit">立即保存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

            <el-dialog
                title="修改配送日期信息"
                :visible.sync="drawerDeShow"
                :before-close="handleClose">
                <el-form ref="form" :model="formDataDelivery" label-width="80px" class="draw-box">
                    <el-form-item label="子订单号" required>
                        <el-input v-model="formDataDelivery.externalOrderNo" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="配送日期">
                        <el-date-picker v-model="formDataDelivery.deliverDate" type="date" placeholder="选择日期"
                                        value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="deliveryTimeSubmit">立即保存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <!-- 抓单 -->
            <el-dialog title="抓单" :visible.sync="fetchOrderShow" :before-close="handleClose">
                <el-form ref="form" :model="formDataFetchOrder" label-width="80px" class="draw-box">
                    <el-form-item label="店铺" required>
                        <el-select v-model="formDataFetchOrder.shopId" clearable placeholder="选择店铺" size="mini">
                            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单号" required>
                        <el-input type="textarea" placeholder="多个订单逗号隔开" v-model="formDataFetchOrder.orderNo" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="fetchOrder">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </tablePagination>
    </div>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'

export default {
    data() {
        return {
            loading: false,
            tableList: [],
            total: 0,
            addressCityList: [],
            shopList: [],
            cityValue: '',
            formParam: {
                pageSize: 10,
                currentPage: parseInt(this.$route.params.currentPage) || 1,
                externalOrderNo: this.$route.query.externalOrderNo ||'',
                externalMainOrderNo: this.$route.query.externalMainOrderNo || '',
                sourceOrderMainNo: '',
                consigneeMobile: '',
                consigneeName: '',
                shopName: '全部',
                buyerId: this.$route.params.id
            },
            formData: {
                externalOrderNo: '',
                addrId: '',
                consingeephone: '',
                consigneename: '',
                address: '',
                areaid: null,
                areaName: null,
                cityName: null,
                provinceName: null,
                customerId: '',
            },
            formDataDelivery: {
                externalOrderNo: '',
                deliverDate: '',
                customerId: '',
            },
            formDataFetchOrder: {
                shopId: '',
                orderNo: ''
            },
            drawerShow: false,
            drawerDeShow: false,
            fetchOrderShow: false,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的
                }
            },
            orderStatusState: {
                0: {name: "已取消", type: 'info'},
                5: {name: "待支付", type: 'warning'},
                10: {name: "已支付", type: 'success'},
                15: {name: "部分配货", type: 'success'},
                20: {name: "全部配货", type: 'success'},
                25: {name: "部分发货", type: 'success'},
                30: {name: "已发货", type: 'success'},
                35: {name: "已送达", type: 'success'},
                40: {name: "交易完成", type: 'primary'},
                45: {name: "交易关闭", type: 'danger'},
            }
        }
    },
    components: {
        defaultSec, tablePagination
    },
    mounted() {
        if (this.$route.params.id) {
            this.initList();
        }
        this.getAddressCityList();
        this.getShopList();
    },
    methods: {
        initList() {
            this.loading = true
            this.$apiGet('lechun-csms/orderApi/getOrderInfoByPage', this.formParam).then(res => {
                console.log(res)
                this.loading = false
                this.total = parseInt(res.records);
                this.tableList = res.rows.map(item => {
                    item.erroMessageNameHtml = item.erroMessageName.replace(/\(/g, ":").replace(/\)/g, "\n")
                    item.addressHtml = item.consigneeProvince + ' ' + item.consigneeCity + ' '
                        + item.consigneeArea + ' ' + item.consigneeAddress;
                    return item
                });
            }).catch(() => {
                this.loading = false;
            });
        },
        getAddressCityList() {
            this.$apiGet('lechun-customers/customer/getProvinceCityAreaByErp', {
                transportType: -1,
                isLongPeriodBuy: 0
            }).then(res => {
                console.log(res)
                let add = res.provinceVoList
                let newArr = add.map(item => {
                    item = this.setCityKey(item)
                    item.VoList = item.VoList.map(item1 => {
                        item1 = this.setCityKey(item1)
                        if (item1.VoList != null) {
                            item1.VoList = item1.VoList.map(item2 => {
                                item2 = this.setCityKey(item2)
                                return item2
                            })
                        }
                        return item1
                    })
                    return item
                })
                this.addressCityList = newArr
            })

        },
        getShopList() {
            this.$apiGet('lechun-csms/shop/shopList').then(res => {
                console.log("shop/shopList", res);
                this.shopList = res;
            });
        },
        //查询订单
        searchOrder(){
            this.formParam.currentPage = 1
            this.initList()
        },
        setCityKey(obj) {
            let newObj = {}
            let reg = /Name|Id|VoList/
            for (let key in obj) {
                if (reg.test(key)) {
                    newObj[key.match(reg)[0]] = obj[key]
                }
            }
            return newObj
        },


        handleCasChange(val) {
            console.log(val)
            this.formData.areaid = val.length >= 3 ? val[2] : '';
            var provinceName = '';
            var cityName = '';
            var areaName = '';
            var breakFlag = false;
            for (var i = 0; i < this.addressCityList.length; i++) {
                for (var j = 0; j < this.addressCityList[i].VoList.length; j++) {
                    if (this.addressCityList[i].VoList[j].VoList != null) {
                        for (var k = 0; k < this.addressCityList[i].VoList[j].VoList.length; k++) {
                            if (this.formData.areaid == this.addressCityList[i].VoList[j].VoList[k].Id) {
                                areaName = this.addressCityList[i].VoList[j].VoList[k].Name;
                                cityName = this.addressCityList[i].VoList[j].Name;
                                provinceName = this.addressCityList[i].Name;
                                breakFlag = true;
                                break;
                            }
                        }
                    }
                    if (breakFlag) {
                        break;
                    }
                }
                if (breakFlag) {
                    break;
                }
            }
            this.formData.areaName = areaName;
            this.formData.cityName = cityName;
            this.formData.provinceName = provinceName;
            console.log('formData', this.formData);
        },
        handlePage(page) {
            this.formParam.currentPage = page;
            console.log('bu', this.formParam.buyerId)
            // if (this.formParam.buyerId) {
            //
            // } else {
            //     this.$router.push({path: '/orderList', name: 'orderList', params: {currentPage: page}})
            // }
            this.initList()
        },

        formatDate(datetime) {
            if (datetime == null) {
                return;
            }
            var date = new Date(datetime);
            var year = date.getFullYear(),
                month = ("0" + (date.getMonth() + 1)).slice(-2),
                sdate = ("0" + date.getDate()).slice(-2),
                hour = ("0" + date.getHours()).slice(-2),
                minute = ("0" + date.getMinutes()).slice(-2),
                second = ("0" + date.getSeconds()).slice(-2);
            // 拼接
            // var result = year + "-"+ month +"-"+ sdate +" "+ hour +":"+ minute +":" + second;
            var result = year + "-" + month + "-" + sdate;
            // 返回
            return result;
        },
        toDetail(externalOrderNo, shopName) {
            this.$router.push({
                path: '/orderListOld/:externalOrderNo/:shopName',
                name: 'orderDetailOld',
                params: {externalOrderNo: externalOrderNo, shopName: shopName}
            })
        },
        toDetail2(externalOrderNo) {
            this.$router.push({
                path: '/orderList/:externalOrderNo',
                name: 'orderDetail',
                params: {externalOrderNo: externalOrderNo}
            })
        },

        //用户详情
        toBuyer(id) {
            this.$router.push({name: 'userDetail', params: {id: id}})
        },

        //审批
        toCheck(externalOrderNo) {
            this.$apiGet('lechun-csms/orderApi/checkOrder', {externalOrderNo: externalOrderNo}).then(res => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                this.initList();
                //跳转到订单列表页
                //this.$router.push({path:'/orderList',name:'orderList',params:{}})
            })
        },

        //退款
        toRefund(externalOrderNo, pageType, orderSource, customerId) {
            if (orderSource == 26 || orderSource == 28 || orderSource == 29 || orderSource == 30 || orderSource == 31
                || orderSource == 32 || orderSource == 33 || orderSource == 34 || orderSource == 36) {
                this.$confirm('预付卡实物订单需要在用户信息里的预付卡tab页取消', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({
                        path: '/userDetail/:customerId',
                        name: 'refundsDetail',
                        params: {id: customerId}
                    })
                }).catch(() => {
                    this.$router.push({
                        path: '/userDetail/:customerId',
                        name: 'refundsDetail',
                        params: {id: customerId}
                    })
                });
            } else {
                this.$router.push({
                    path: '/refundsList/:externalOrderNo/:pageType/:orderSource',
                    name: 'refundsDetail',
                    params: {orderNo: externalOrderNo, pageType: pageType, orderSource: orderSource}
                });
            }
        },

        //修改订单配送信息
        toEditDelivery(item) {
            this.drawerShow = true;
            console.log('this.addressCityList', this.addressCityList);
            console.log('item', item);
            if (item) {
                this.formData.addrId = item.addrId
                this.formData.consingeephone = item.consigneeMobile
                this.formData.consigneename = item.consigneeName
                this.formData.address = item.consigneeAddress
                this.formData.areaid = item.areaId
                this.formData.areaName = item.consigneeArea
                this.formData.cityName = item.consigneeCity
                this.formData.provinceName = item.consigneeProvince
                this.formData.externalOrderNo = item.externalOrderNo
                this.formData.customerId = item.buyerId
                var provinceId, cityId, areaId;
                for (var i = 0; i < this.addressCityList.length; i++) {
                    if (this.addressCityList[i].Name == item.consigneeProvince) {
                        provinceId = this.addressCityList[i].Id;
                        var citys = this.addressCityList[i].VoList;
                        for (var j = 0; j < citys.length; j++) {
                            if (citys[j].Name == item.consigneeCity) {
                                cityId = citys[j].Id;
                                var areas = citys[j].VoList;
                                for (var k = 0; k < areas.length; k++) {
                                    if (areas[k].Name == item.consigneeArea) {
                                        areaId = areas[k].Id;
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }
                }
                this.cityValue = [provinceId, cityId, areaId];
            }
        },

        resetForm() {
            this.formData.addrId = ''
            this.formData.consingeephone = ''
            this.formData.consigneename = ''
            this.formData.address = ''
            this.formData.areaid = ''
            this.formData.areaName = ''
            this.formData.cityName = ''
            this.formData.provinceName = ''
            this.formData.externalOrderNo = ''
            this.formData.customerId = ''
            this.formDataFetchOrder = {
                shopId: '',
                orderNo: ''
            }
        },

        //保存提交
        addressSubmit() {
            // if (this.formData.consingeephone.length != 11) {
            //     this.$notify({
            //         title: '警告',
            //         message: '请输入正确的手机号',
            //         type: 'warning'
            //     });
            //     return false
            // }
            console.log('cityValue', this.cityValue);
            console.log('formData', this.formData);
            var formJson = {
                externalOrderNo: this.formData.externalOrderNo,
                customerId: this.formData.customerId,
                consigneeName: this.formData.consigneename,
                consigneeMobile: this.formData.consingeephone,
                consigneeAddress: this.formData.address,
                consigneeAreaid: this.formData.areaid,
                consigneeAreaName: this.formData.areaName,
                consigneeCityName: this.formData.cityName,
                consigneeProvinceName: this.formData.provinceName,
            };
            console.log('formJson', JSON.stringify(formJson));
            this.$apiGet('lechun-csms/orderApi/editOrderAddr', {editOrderParam: JSON.stringify(formJson)}).then(res => {
                this.drawerShow = false;
                this.$message({
                    message: '恭喜你，地址保存成功',
                    type: 'success'
                });
                this.initList()
                this.resetForm()
            })
        },

        //修改配送日期信息
        toEditDeliveryTime(item) {
            this.drawerDeShow = true;
            if (item) {
                this.formDataDelivery.externalOrderNo = item.externalOrderNo;
                this.formDataDelivery.customerId = item.buyerId;
                this.formDataDelivery.deliverDate = this.formatDate(item.deliveryTime);
            }
        },

        resetDeliveryForm() {
            this.formDataDelivery.deliverDate = ''
            this.formDataDelivery.customerId = ''
            this.formDataDelivery.externalOrderNo = ''
        },
        //保存配送日期提交
        deliveryTimeSubmit() {
            var formJson = {
                externalOrderNo: this.formDataDelivery.externalOrderNo,
                // customerId: this.formDataDelivery.customerId,
                deliverDate: this.formDataDelivery.deliverDate

            };
            console.log(JSON.stringify(formJson));
            this.$apiGet('lechun-csms/orderApi/editOrderDeliveryTime', {editOrderParam: JSON.stringify(formJson)}).then(res => {
                this.drawerDeShow = false;
                this.$message({
                    message: '恭喜你，配送日期保存成功',
                    type: 'success'
                });
                this.initList()
                this.resetDeliveryForm()
            })
        },

        handleClose(done) {
            done();
            this.resetForm()
        },

        formatOrderCreateMethod: function (row, column) {
            if (row.orderCreateMethod == 1) {
                return "线上-拉单";
            }
            if (row.orderCreateMethod == 2) {
                return "线下-手动建单";
            }
            if (row.refundStatus == 3) {
                return "导入";
            }
        },
        getJDorder() {
            this.$prompt('请输入订单号，回车分割多个订单号', '抓取京东订单', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'text'
            }).then(({value}) => {
                this.$apiGet('lechun-csms/orderApi/getTradesByTids', {
                    'shopName': '京东POP-低温',
                    'tids': value
                }).then(res => {
                    this.$message({
                        message: '同步成功',
                        type: 'success'
                    });
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消输入'
                // });
            });
        },
        getThirdOrder(){
            this.formDataFetchOrder = {
                shopId: '',
                orderNo: ''
            };
            this.fetchOrderShow = true;
        },
        fetchOrder(){
            this.$apiGet('lechun-csms/orderApi/getTradesByTids', this.formDataFetchOrder).then(res => {
                this.$message({
                    message: '同步成功',
                    type: 'success'
                });
            });
        },
        getTMorder() {
            this.$prompt('请输入订单号，回车分割多个订单号', '抓取天猫订单', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'text'
            }).then(({value}) => {
                this.$apiGet('lechun-csms/orderApi/getTradesByTids', {'shopName': '天猫商城', 'tids': value}).then(res => {
                    this.$message({
                        message: '同步成功',
                        type: 'success'
                    });
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消输入'
                // });
            });
        },
        getMallorder() {
            this.$prompt('请输入主订单号', '抓取微信订单', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'text'
            }).then(({value}) => {
                this.$apiGet('lechun-csms/orderApi/getMallOrderByOrderMainNo', {'orderMainNo': value}).then(res => {
                    this.$message({
                        message: '同步成功',
                        type: 'success'
                    });
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消输入'
                // });
            });
        },
        setRemarkhow(externalOrderNo, remark) {
            this.$prompt('请输入备注', '提示', {
                inputValue: remark,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({value}) => {
                this.$apiGet('lechun-csms/orderApi/saveRemark', {
                    'externalOrderNo': externalOrderNo,
                    'remark': value
                }).then(v => {
                    this.$message({
                        message: '拦单成功',
                        type: 'success'
                    });
                    this.initList();
                });
            }).catch(() => {

            });
        },
        orderFlow(orderNo) {
            this.$confirm('确定继续流转吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$apiGet('lechun-csms/orderApi/orderFlow', {'orderNo': orderNo}).then(res => {
                    this.$message({
                        message: '流转成功',
                        type: 'success'
                    });
                    this.initList();
                });
            }).catch(() => {

            });
        },
        cancelRealOrder(row){
            this.$confirm('此操作将取消该抖音长期购的本次发货,请确保已经给用户退款, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$apiGet('lechun-csms/refund/refundPrepayCard', {orderNo: row.externalMainOrderNo}).then(res => {
                    this.$notify({
                        title: '成功',
                        message: '取消成功',
                        type: 'success'
                    });
                    this.initList();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        //操作订单
        handleGroupItem(dataItem){
            let item = dataItem.item
            //备注拦单
            if (dataItem.state == 'remarkOrder') {
                this.setRemarkhow(item.externalOrderNo, item.remark)
            }
            //退款
            if (dataItem.state == 'refundOrder') {
                this.toRefund(item.externalOrderNo, 1, item.orderSource, item.buyerId)
            }
            //审批
            if (dataItem.state == 'checkOrder') {
                this.toCheck(item.externalOrderNo)
            }
            //取消计划
            if (dataItem.state == 'cancelOrder') {
                this.cancelRealOrder(item)
            }
            if (dataItem.state == 'setOrderRecommend') {
                this.setOrderRecommend(item);
            }
        },
        //复制单号
        copyOrderNo(text){
            var input = document.createElement("textarea"); // 创建input对象
            input.value = text; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message({
                type: 'success',
                message: '已复制到剪贴板'
            });
        },
        isEmpty(v){
            if (v == '' || v == null) {
                return false;
            }
            return true;
        },
        setOrderRecommend(item) {
            var orderNo = item.externalOrderNo;
            if (item.shopId == '1325743461067264001' || item.shopId == '1325743461067264000') {//小程序、微信
                orderNo = item.externalMainOrderNo;
            }
            if (item.customerName != '微信') {
                this.$prompt('请输入CUSID或OPENID(企微侧边栏有显示)，将该订单绑定该用户并加入到补差价列表', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValidator: this.isEmpty,
                    inputErrorMessage: '不能为空'
                }).then(({value}) => {
                    this.$apiGet('lechun-cms/prepay/execPrepayRecommends', {
                        orderMainNo: orderNo,
                        openId: value
                    }).then(res => {
                      //判断返回的message是否是没有手机号的错误，需要弹出收入手机号，message=mobilenull
                      console.log("返回值:"+res);
                      if(res=='mobilenull'){
                        var openId=value;
                        this.$prompt('系统无法检测到用户手机号，请输入该用户手机号', '提示', {
                          confirmButtonText: '确定',
                          cancelButtonText: '取消',
                          inputValidator: this.isEmpty,
                          inputErrorMessage: '不能为空'
                        }).then(({value}) => {
                          this.$apiGet('lechun-cms/prepay/execPrepayRecommends', {
                            orderMainNo: orderNo,
                            openId: openId,
                            phone:value
                          }).then(res => {
                              console.log("value:"+openId+"value1:"+value);
                              this.$notify({
                                title: '成功',
                                message: '设置成功',
                                type: 'success'
                              });
                              this.initList();
                          })
                        }).catch(() => {
                          this.$message({
                            type: 'info',
                            message: '已取消'
                          });
                        });
                      }else{
                        this.$notify({
                          title: '成功',
                          message: '设置成功',
                          type: 'success'
                        });
                        this.initList();
                      }

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            } else {
                this.$confirm('该操作将该订单加入到补差价列表', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$apiGet('lechun-cms/prepay/execPrepayRecommends', {
                        orderMainNo: orderNo,
                        openId: ''
                    }).then(res => {
                        this.$notify({
                            title: '成功',
                            message: '设置成功',
                            type: 'success'
                        });
                        this.initList();
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }

        }
    }
}
    //props:['formParam'],
</script>
<style scoped>
.expand-title {
    width: 70px;
    color: #444548;
    line-height: 30px;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;
}

.expand-flex {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.copy-text{
    white-space: pre-line;
    margin: 0;
    line-height: 18px;
    background: #f2f2f2;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 6px;
    max-height: 130px;
    overflow-y: scroll;
    scrollbar-width: none;
}
.address{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
