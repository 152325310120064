<template>
    <defaultSec :title="'售后类型管理'">
        <div style="margin-bottom: 10px">
            <el-button type="danger" size="mini" @click="editType({})">添加售后类型</el-button>
        </div>
        <el-tabs type="border-card">
            <template v-for="item in tabList">
                <el-tab-pane :label="item.label">
                    <el-table :data="item.children" border size="mini">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="label" label="类型名称"></el-table-column>
                        <el-table-column prop="p_label" label="类型所属">
                            <template slot-scope="scope">
                                <el-tag
                                    size="mini"
                                    :type="scope.row.p_value==1?'danger':scope.row.p_value==2?'success':'warning'"
                                >{{scope.row.p_label}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="200">
                            <template slot-scope="scope">
                                <el-button type="danger" size="mini" @click="editType(scope.row)">编辑</el-button>
                                <el-button type="success" size="mini" @click="editDetailType(scope.row)">
                                    责任明细
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>

            </template>
        </el-tabs>
        <el-dialog
            title="新增/修改售后类型"
            :visible="editTypeVisible"
            :before-close="handleClose">
            <el-form ref="form" :model="formData" label-width="90px" class="draw-box">
                <el-form-item label="类型名称" required>
                    <el-input v-model="formData.workOrderTypeName" placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="类型所属">
                    <el-select :disabled="formData.id?true:false" style="width: 100%" v-model="formData.parentId" placeholder="请选择">
                        <el-option
                            v-for="item in tabList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="orderTypeSubmit">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-drawer
            size="70%"
            title="责任人明细"
            :visible.sync="detailVisible"
            :before-close="handleClose">
            <div style="padding: 10px">
                <el-alert
                    title="注意：「质量」类型下的责任人修改，同产品线下责任人将同步修改；「运营」类型下的责任人修改，同平台的责任人将同步修改；请知悉"
                    type="warning"
                    show-icon>
                </el-alert>
                <template v-if="tableType==2">
                    <el-tabs type="border-card">
                        <template v-for="item in tableTypeList">
                            <el-tab-pane :label="item.label">
                                <el-table :data="item.children" border size="mini">
                                    <el-table-column type="index" label="序号"></el-table-column>
                                    <el-table-column prop="label" label="产品线名称"></el-table-column>
                                    <el-table-column prop="p_label" label="常/低温">
                                        <template slot-scope="scope">
                                            <el-tag
                                                size="mini"
                                                :type="scope.row.p_label=='低温'?'danger':'success'"
                                            >{{scope.row.p_label}}</el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="respUserId" label="责任人">
                                        <template slot-scope="scope">
                                            <div v-for="item in scope.row.children" style="display: flex;align-items: center;margin-bottom: 5px">
                                                <el-tag type="info">{{item.label}}</el-tag>
                                                <el-select @change="(val)=>handleSubmitUser(val,item,scope.row)" size="small" style="width: 100%" v-model="item.respUserId" placeholder="请选择">
                                                    <el-option
                                                        v-for="item1 in userList"
                                                        :key="item1.USER_ID"
                                                        :label="item1.USER_NICK"
                                                        :value="item1.USER_ID">
                                                    </el-option>
                                                </el-select>
<!--                                                <el-popconfirm-->
<!--                                                    title="确定修改责任人吗？"-->
<!--                                                    @confirm="handleSubmitUser(scope.row)"-->
<!--                                                >-->
<!--                                                    <el-button slot="reference" size="small" type="success">提交</el-button>-->
<!--                                                </el-popconfirm>-->

                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                        </template>
                    </el-tabs>
                </template>
                <template v-else>
                    <el-table :data="tableTypeList" border size="mini">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column prop="label" :label="tableType==3?'平台名称':'名称'"></el-table-column>
                        <el-table-column prop="respUserId" label="责任人">
                            <template slot-scope="scope">
                                <div style="display: flex;align-items: center;margin-bottom: 5px">

                                    <el-select clearable filterable @change="(val)=>handleSubmitUser(val,scope.row)" size="small" style="width: 100%" v-model="scope.row.respUserId" placeholder="请选择">
                                        <el-option
                                            v-for="item in userList"
                                            :key="item.USER_ID"
                                            :label="item.USER_NICK"
                                            :value="item.USER_ID">
                                        </el-option>
                                    </el-select>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>

            </div>

        </el-drawer>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
export default {
    name: "workOrderType",/*此处的name必须有且与include中的一致*/
    data() {
        return {
            tableList:[],
            tableTypeList:[],
            tabList:[],
            userList:[],
            total:0,
            tableType:0,
            formData:{},
            editTypeVisible:false,
            detailVisible:false
        }
    },
    components:{
        defaultSec,tablePagination
    },
    mounted(){
        this.initList()
        this.initUserList()
    },
    activated(){
    },
    methods: {
        initList() {
            this.$apiGet('lechun-csms/workOrder/workOrderTypeList',{}).then(res => {
                console.log(res)
                let setTableList = []
                res.map(item => {
                    setTableList = [...setTableList,...item.children]
                })
                this.tabList = res
                this.tableList = setTableList

            })
        },
        initUserList() {
            this.$apiGet('lechun-baseservice/user/getUserListForDorpbox',{}).then(res => {
                this.userList = res
            })
        },
        editType(item){
            console.log(item)
            this.formData = {id:item.value,parentId:item.p_value,workOrderTypeName:item.label}
            this.editTypeVisible = true;
        },
        editDetailType(val){
            console.log(val)
            this.detailVisible = true;
            this.tableTypeList = [];
            if(val.p_value==1){
                this.tableTypeList = [{...val}]
            }else if(val.p_value==2){
                // val.children = val.children.map(item => {
                    this.tableTypeList = [...this.tableTypeList,...val.children]
                // })
            }else{
                this.tableTypeList = val.children;
            }
            this.tableType = val.p_value;
        },
        handleClose(){
            this.editTypeVisible = false;
            this.detailVisible = false;
        },
        orderTypeSubmit(){
            console.log(this.formData)
            this.$apiGet('lechun-csms/workOrder/saveWorkOrderType',this.formData).then(res => {
                console.log(res)
                this.editTypeVisible = false;
                this.initList()
            })
        },
        handleSubmitUser(val,item,row){
            console.log(22,this.tableType,val,item,row)
            let type = this.tableType
            let param = {
                responseUserId:val,
                code1:type==1?item.value:null,//物流类
                code2_transType:type==2?row.p_value:null,//质量常低温
                code2_matName:type==2?item.p_value:null,//质量产品线
                code2_factory:type==2?item.value:null,//质量工厂
                code3_platform:type==3?item.value:null,//运营平台
            }
            this.$apiGet('lechun-csms/workOrder/saveWorkOrderTypeResponseUser',{...param}).then(res => {
                console.log(res)
                this.$message({
                    message: '修改成功',
                    type: 'success',
                    duration: 2000,
                    onClose:()=>{
                        this.handleClose()
                    }
                })
                this.initList()

            })
        }
    }
}
</script>


<style scoped lang="scss">
.work-order-detail-info {
    .box-card {
        margin-bottom: 10px;
    }

    .el-card__header {
        padding: 10px;
    }

    .el-card__body {
        padding: 0px;
    }

    .popover-title {
        margin: 0 0 15px 0;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }

    .el-tag {
        margin-right: 10px;
    }
}
</style>