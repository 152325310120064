<template>
    <defaultSec :title="'工作日历管理'">
        <section class="work-calendar-list">
            <div class="search-content">
                <el-form :inline="true" size="mini" class="demo-form-inline">
                    <el-form-item label="年度选择">
                        <el-date-picker
                            v-model="formParam.year"
                            type="year"
                            format="yyyy"
                            value-format="yyyy">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="checkList">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="warning" @click="setYearCalendar">构建年度日历</el-button>
                    </el-form-item>
                </el-form>

            </div>
            <el-calendar v-model="currentDate" v-loading="loading">
                <template slot="dateCell" slot-scope="{data}">
                    <p>{{data.day.split('-').slice(1).join('-')}}</p>

                    <template v-for="item in calendarList">
                        <div style="position: relative;top: -24px" v-if="data.day==item.date.split(' ')[0]">
                            <div style="font-size:12px;display: flex;justify-content: space-between">
                                <p></p>
                                <el-tag size="mini" type="primary" v-if="item.isHoliday===0">{{ item.holidayName}}</el-tag>
                                <el-tag size="mini" type="danger" v-if="item.isHoliday===1">{{ item.holidayName}}</el-tag>
                                <el-tag size="mini" type="danger" v-if="item.isHoliday===2">{{ item.holidayName}}</el-tag>
<!--                                <span style="color: #2dacd1" type="primary" v-if="item.isHoliday===0">{{ item.holidayName}}</span>-->
<!--                                <span style="color: #e85656" type="success" v-if="item.isHoliday===1">{{ item.holidayName}}</span>-->
<!--                                <span style="color: #e85656" type="danger" v-if="item.isHoliday===2">{{ item.holidayName}}</span>-->
                            </div>
                            <div style="margin-top: 15px;font-size: 10px;position: relative">
                                <div  style="position: absolute;right: 0;top: -8px">
                                    <el-popover
                                        popper-class="popper-content"
                                        placement="top"
                                        width="400"
                                        size="mini"
                                        v-model="item.visible"
                                        trigger="manual">
                                        <h4 style="margin: 2px 0 20px">修改工作日程</h4>
                                        <el-alert
                                            title="【工作日】的所选人员是当天不上班的,【休息日】的所选人员是当天上班的，没有则不选"
                                            type="warning"
                                            show-icon :closable="false" style="margin-bottom: 10px">
                                        </el-alert>
                                        <el-form size="mini" ref="form" :model="item" label-width="80px">
                                            <el-form-item label="工作类型">
                                                <el-select :popper-append-to-body="false" size="mini" v-model="item.qyWorkType">
                                                    <el-option label="工作日" :value="0"></el-option>
                                                    <el-option label="休息日" :value="1"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="选择人员">
                                                <el-select multiple clearable :popper-append-to-body="false" size="mini" value-key="id" v-model="item.qyWeixinUserList" @change="(e)=>selQwUser(item,e)">
                                                    <el-option v-for="user in userList" :label="user.name" :value="user"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-form>
                                        <el-button size="mini" style="float: right;margin-top: 10px;margin-left: 10px" type="info" @click="item.visible = false">关闭</el-button>

                                        <el-button size="mini" style="float: right;margin-top: 10px" type="danger" @click="saveWorkCalendar(item)">保存</el-button>
                                        <el-button slot="reference" size="mini" type="text" icon="el-icon-edit" @click="item.visible = !item.visible"></el-button>
                                    </el-popover>
                                </div>
                                <p style="font-size:12px;margin-bottom: 6px"><span :style="{color: item.qyWorkType===0?'#55a532':'#E6A23C'}">● </span> {{item.qyWorkType===0?'工作日':'休息日'}}</p>
                                <p v-if="item.qyWeixinUserNameList.length>0"><span :style="{color: item.qyWorkType===0?'#e50011':'#55a532'}">● </span> {{item.qyWorkType===0?'休假人员':'工作人员'}}:</p>
                                <div style="padding-left: 10px;margin-top: 10px;max-height: 62px;overflow-y: scroll;scrollbar-width: none;">
                                    <p v-for="user in item.qyWeixinUserNameList"><span style="color: #55a532;">- </span>{{user}}</p>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </el-calendar>
        </section>

    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'

export default {
    name: "workCalendar",
    data(){
        return {
            loading:false,
            total: 0,
            year:new Date().Format("yyyy"),
            formParam: {
                pageSize: 366,
                currentPage: 1,
                year:new Date().Format("yyyy")
            },
            currentDate:new Date().Format('yyyy-MM-dd'),
            calendarList: [],
            userList: [],
        }
    },
    components: {defaultSec,tablePagination},
    mounted() {
        this.initList()
        this.initUserList()
    },
    methods: {
        initList() {
            this.loading = true
            this.$apiGet('lechun-cms/scrmCalendar/getPageList', this.formParam).then(res => {
                this.calendarList = res.list.map(item=>{
                    item.qyWeixinUserList = []
                    item.qyWeixinUserIdList = item.qyWeixinUserId?item.qyWeixinUserId.split(','):[]
                    item.qyWeixinUserNameList = item.qyWeixinUserName?item.qyWeixinUserName.split(','):[]
                    item.qyWeixinUserIdList.map((user,index)=>{
                        item.qyWeixinUserList.push({name:item.qyWeixinUserNameList[index],id:user})
                    })
                    item.visible = false
                    return item
                })
                this.total = res.total
                this.loading = false
            })
        },
        //查询日历
        checkList(){
            this.currentDate = this.formParam.year
            this.initList()
        },
        //企微客服列表
        initUserList(){
            this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                console.log(res)
                this.userList = res
            })
        },
        //构建年度日历
        setYearCalendar(){
            this.$apiGet('lechun-cms/scrmCalendar/builderCalendar', {year:this.year}).then(res => {
                console.log(res)
            })
        },
        //选择人员
        selQwUser(item,value){
            console.log(item,value)
            item.qyWeixinUserIdList = value.map(user=>{
                user = user.id
                return user
            })
            item.qyWeixinUserNameList = value.map(user=>{
                user = user.name
                return user
            })
        },
        saveWorkCalendar(item){
            console.log(item)
            this.$apiGet('lechun-cms/scrmCalendar/saveEdit', {
                date:item.date,
                qyWorkType:item.qyWorkType,
                qyWeixinUserId:item.qyWeixinUserIdList.join(',')
            }).then(res => {
                console.log(res)
                item.visible = false
            })
        },

    }
}
</script>

<style   lang="scss" rel="stylesheet/scss">
.work-calendar-list{
    .search-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .scene-left{
        width: 140px;
        border-radius: 5px 0 0 5px;
        height: calc(100% - 20px);
        padding: 10px;
        background: #f5f5f5;
        font-size: 12px;
        box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);
        position: relative;
        z-index: 9;
    }
    .scene-left-item{
        cursor: pointer;
    }
    .scene-left-item-on{
        background: rgba(32,158,145,.2);
    }
    .scene-right{
        flex: 1;
        height: calc(100% - 20px);
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        padding: 10px 10px 10px 15px;
    }
    .copy-text{
        white-space: pre-line;
        margin: 0;
        line-height: 18px;
        background: #f2f2f2;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 6px;
        max-height: 120px;
        overflow:auto;
    }
    p{
        margin: 0;
    }
    .el-calendar-table .el-calendar-day{
        height: 160px;

    }
    .el-calendar-table{
        width: 100%;
        height: 100%;
        &:not(.is-range){
            //使不是本月的日期不可点击，不会跳转到其他月份
            td.next{
                pointer-events: none;
            }
            td.prev{
                pointer-events: none;
            }
            //td{
            //    pointer-events: none;
            //}
        }
    }

}
/*下拉框高度*/
.popper-content .el-select-dropdown__wrap{
  max-height: 200px!important;
}

</style>